<template>
  <v-app>
    <v-app-bar v-if="isIOS" app color="white" flat dense></v-app-bar>
    <v-main>
      <v-app-bar :app="!isIOS" dense flat color="deep-purple accent-4" dark class="notranslate">
        <v-btn icon v-if="backActive" @click.prevent="backState">
          <v-icon>mdi-arrow-left-bold</v-icon>
        </v-btn>

        <v-btn icon to="/chat" v-if="chatActive">
          <v-badge
            v-if="newMessages > 0"
            offset-y="12"
            color="red"
            :content="newMessages"
          >
            <v-icon class="__shake">mdi-message-text-outline</v-icon>
          </v-badge>
          <v-icon v-else>mdi-message-text-outline</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-toolbar-title>
          <v-img
            height="40px"
            :src="logo"
            width="180px"
            @click.prevent="redirectHome"
          />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>

      <!-- NAV -->
      <v-navigation-drawer v-model="drawer" absolute temporary class="notranslate">
        <v-system-bar color="deep-purple darken-3"></v-system-bar>

        <v-list dense color="deep-purple accent-4" dark>
          <v-list-item link to="/profile">
            <v-list-item-avatar>
              <v-img :src="profilePictureUrl"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ user.firstName }} {{ user.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <!-- 					<v-list-item-action>
						<v-icon @click.prevent="redirectProfile">mdi-menu-down</v-icon>
					</v-list-item-action> -->
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item-group
            v-model="selectItemMenu"
            color="deep-purple darken-5"
          >
            <v-list-item exact to="/profile">
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-title>
                {{ $t("home.profile") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!isIOS"
              exact
              @click.prevent="redirectHelpDesk"
              :disabled="!avaibleChatSupport"
            >
              <v-list-item-icon
                ><v-icon>mdi-help-circle-outline</v-icon></v-list-item-icon
              >
              <v-list-item-title>
                {{ $t("home.txtHelpSupport") }}
              </v-list-item-title>
            </v-list-item>

            <!--           <v-list-item @click.prevent="checkUpdate" :disabled="!registration">
            <v-list-item-icon
              ><v-icon>mdi-cellphone-arrow-down</v-icon></v-list-item-icon
            >
            <v-list-item-title>
              {{ $t("home.txtButtomUpdate") }}</v-list-item-title
            >
          </v-list-item> -->

            <v-list-item to="/select-unit">
              <v-list-item-icon><v-icon>mdi-car</v-icon></v-list-item-icon>
              <v-list-item-title>
                {{ $t("home.txtSelectUnit") }}</v-list-item-title
              >
            </v-list-item>

            <v-list-item @click.prevent="closedSession">
              <v-list-item-icon
                ><v-icon>mdi-lock-open-outline</v-icon></v-list-item-icon
              >
              <v-list-item-title> {{ $t("home.logout") }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!---LIVE TRACKER-->
        <LiveTracker/>
        <!---LIVE TRACKER-->
        <v-list
          two-line
          color="purple lighten-5"
          v-if="user.currentTransportUnit"
        >
          <v-list-item-group active-class="black--text">
            <v-list-item disabled>
              <v-list-item-content>
                <v-list-item-title
                  >{{ $t("home.txtLicense") }}:
                  {{ user.licenseNumber }}</v-list-item-title
                >
                <v-list-item-title
                  >{{ $t("home.txtUnit") }}:
                  {{ user.currentTransportUnit.name }}</v-list-item-title
                >
                <v-list-item-title
                  >{{ $t("home.txtPlates") }}:
                  {{ user.currentTransportUnit.plateNumber }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <!-- END NAV -->

      <router-view class="mb-12 full-h" />
    </v-main>

    <v-bottom-navigation
      fixed
      horizontal
      :input-value="activeNav"
      :value="selectedItem"
      grow
      v-if="activeNav"
      :background-color="'#E5E3FF'"
    >
      <v-btn
        text
        v-for="(item, index) in itemsNav"
        :key="index"
        @click.prevent="actionNavigation(item, index)"
      >
        <span>{{ item.name }}</span>
        <v-icon left>{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- Lector del QR -->
    <qrreader :onlyEvent="onlyEvent" :select="qrSelect" />

    <!-- Dialogo de progreso -->

    <v-dialog
      v-model="dialogLoading"
      persistent
      width="200"
      dark
      class="loadingBlock"
    >
      <v-card color="deep-purple accent-4" dark>
        <v-card-text class="pt10">
          <span class="text-white">{{ $t("home.waiting") }}...</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mt-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Notification -->
    <v-snackbar v-model="snackbarActive">
      {{ textSnackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="closedNotification">
          {{ $t("global.snackbar.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <offline />
    
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import logo from "@/assets/logo-sc-blanco.svg";
import { auth, firestore, messaging } from "../plugins/firebase.js";
import store from "@/store";
import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor as CapacitorApp } from "@capacitor/core";
import { FCM } from "@capacitor-community/fcm";
const qrreader = () =>
  import(/* webpackChunkName: "qrreader" */ "@/components/QRReader.vue");
import { Intercom } from "@mathieustan/vue-intercom";
import LiveTracker from "../components/liveTrackerWithWatchPosition.vue";
const offline = () =>
  import(/* webpackChunkName: "Offline" */ "@/components/Offline.vue");

export default {
  name: "Home",
  components: { qrreader, offline,LiveTracker },
  data: () => ({
    drawer: false,
    loading: false,
    logo,
    selected: 0,
    listener: null,
    autoAuth: null,
    //watchID: null,
    userData: {
      userId: "",
      name: "",
      email: "",
    },
    intercom: new Intercom({ appId: "b6mt6y88" }),
    avaibleChatSupport: false,
    selectItemMenu: null,
  }),
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    ...mapState("ui", {
      activeNav: (state) => state.navigation.active,
      itemsNav: (state) => state.navigation.items,
      selectedItem: (state) => state.navigation.IndexItemActive,
      backActive: (state) => state.backActive,
      chatActive: (state) => state.chatActive,
      qrActive: (state) => state.qr.active,
      dialogLoading: (state) => state.core.dialogLoading,
      onlyEvent: (state) => state.qr.onlyEvent,
      qrSelect: (state) => state.qr.select,
      textSnackbar: (state) => state.snackbar.text,
    }),
    ...mapState("chat", {
      roomsChat: (state) => state.activeChats,
    }),
    ...mapState({
      lang: (state) => state.lang,
      registration: (state) => state.registration,
    }),
    ...mapGetters(["isIOS"]),
    profilePictureUrl() {
      return this.user?.profilePictureUrl || null;
    },
    driverId() {
      return store.state.auth.user.id;
    },
    yesterday() {
      return this.$moment()
        .utc(-5)
        .subtract(1, "days");
    },
    newMessages() {
      return this.roomsChat.filter(function(item) {
        return item.haveMessages;
      }).length;
    },
    snackbarActive: {
      get: function() {
        return this.$store.state.ui.snackbar.active;
      },
      set: function() {
        this.closedSnackbar();
      },
    },
  },
  methods: {
    ...mapMutations("ui", {
      selectNavigationItem: "selectNavigationItem",
      closedSnackbar: "closedSnackbar",
      onlineEvent: "onlineEvent",
    }),
    ...mapMutations("chat", {
      orderRoomsChat: "orderChats",
    }),
    ...mapActions("auth", { logout: "logout" }),
    ...mapActions("ui", {
      resetQR: "resetQR",
    }),
    ...mapActions("services", {
      resetService: "resetService",
    }),
    checkUpdate() {
      if (this.registration) {
        this.registration.update();
      }
    },
    closedNotification() {
      this.closedSnackbar();
    },
    backState() {
      if (window.history.length > 2) {
        this.$router.back();
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    redirectHelpDesk() {
      // this.$router.push({ name: "helpdesk" });
      this.intercom.show();
    },
    redirectHome() {
      if (this.$route.name !== "Home") {
        this.$router.push({ name: "Home" });
        this.selectNavigationItem(0);
      } else if (this.selectedItem != 0) {
        this.selectNavigationItem(0);
      }
    },
    closedSession() {
      this.resetQR();
      this.resetService();
      this.logout();
      localStorage.clear();
      auth.signOut();
      this.$router.push({ name: "Login" });
    },
    actionNavigation(item, index) {
      if (item.type === "action" && item.action) {
        item.action();
      } else if (item.type === "component") {
        this.selectNavigationItem(index);
      }
    },
    observableAuth() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          if (user.uid !== "driver-" + this.driverId) {
            //protegemos la sesion (si no coincide deslogueamos)
            this.closedSession();
          }
        } else {
          //no logueado
          this.$axios
            .get(`driver/auth/chat/${this.driverId}`)
            .then((resToken) => {
              let token = resToken.data.token;
              auth.signInWithCustomToken(token).catch((err) => {
                console.log("No login auto" + err);
                this.closedSession();
              });
            });
        }
      });
    },
    getLasMessage(roomDoc) {
      return new Promise((success) => {
        roomDoc.ref
          .collection("messages")
          .orderBy("createdAt", "desc")
          .limit(1)
          .get()
          .then((_messages) => {
            success(_messages.docs[0].data());
          })
          .catch(() => {
            success("");
          });
      });
    },

    fillMessageItem(snap) {
      let room = snap.doc;
      let data = room.data();

      let item = {
        serviceNumber: data.serviceNumber,
        client: data.client,
        id: data.room,
        title: "#" + data.room,
        subtitle: "",
        haveMessages: data.clientNewMessage ?? false,
        action: "",
        lastUpdated: data.lastUpdated,
        time: "0",
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
      };

      this.getLasMessage(room).then((lastMessage) => {
        try {
          if (lastMessage !== "") {
            item.subtitle = lastMessage.content ?? "";
            item.action = lastMessage.createdAt;
            item.time = lastMessage.createdAt.toDate();
          }
        } catch (err) {
          console.log(err);
        }
      });

      return item;
    },
    initFirebaseChat() {
      this.autoAuth = this.observableAuth();
      this.listener = firestore
        .collection("chats")
        .where("members.driver", "==", this.driverId)
        .where("lastUpdated", ">", new Date(this.yesterday))
        .orderBy("lastUpdated", "desc")
        .onSnapshot({ includeMetadataChanges: true }, (snap) => {
          let _cuantos = snap.docChanges().length;
          snap.docChanges().forEach((change) => {
            switch (change.type) {
              case "added": //si empiezan una nueva conversacion
                this.roomsChat.push(this.fillMessageItem(change));
                break;
              case "removed":
                this.deleteRoomFromView(change.doc);
                break;
              case "modified":
                this.updateRoom(change);
                break;
              default:
                console.log();
                break;
            }
            if (--_cuantos <= 0) {
              this.orderRoomsChat();
            }
          });
          this.isLoading = false;
          return Promise.resolve();
        });
    },
    deleteRoomFromView(snap) {
      let id_room = snap.data().room;
      this.roomsChat.forEach((element, i) => {
        if (element.id == id_room) {
          this.roomsChat.splice(i, 1);
        }
      });
    },
    updateRoom(snap) {
      let id_room = snap.doc.data().room;
      let roomFound = this.roomsChat.find((_item) => {
        return _item.id == id_room;
      });
      if (roomFound) {
        let newItem = this.fillMessageItem(snap);
        Object.assign(roomFound, newItem);
        this.getLasMessage(snap.doc).then((message) => {
          try {
            roomFound.subtitle = message.content ?? "";
            roomFound.action = message.createdAt;
          } catch (err) {
            console.log(err);
          }
        });
      }
    },

    checkRoute() {
      store.state.ui.chatActive = true;
      switch (this.$route.name) {
        case "ListChat":
          store.state.ui.chatActive = false;
          break;
      }
    },
    async initPushnotificationWeb(){
      await messaging
						.getToken({
							vapidKey: process.env.VUE_APP_FCM_VAPID
						})
						.then(fcmToken => {
							//actualizamos el token del cliente
							try {
								if (fcmToken) {
                  firestore
                  .collection("drivers")
                  .doc(String(this.driverId))
                  .set({ token: fcmToken });
								} else {
									console.log(
										"No registration token available. Request permission to generate one."
									);
								}
								return;
							} catch (error) {
								console.log("Error generating token " + error);
								return;
							}
        });
    },
    initPushMessaging() {
      PushNotifications.requestPermission().then(async (permission) => {
        if (permission.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          await PushNotifications.register();
        } else {
          // No permission for push granted
        }
      });
      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token) => {
        // Enable the auto initialization of the library
        FCM.setAutoInit({ enabled: true });
        // Check the auto initialization status
        FCM.isAutoInitEnabled();
        FCM.getToken()
          .then((fcmToken) => {
            if (fcmToken) {
              firestore
                .collection("drivers")
                .doc(String(this.driverId))
                .set({ token: token.value });
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => console.log("Error generating token" + err));
      });
      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error) => {
        console.log("Error on registration: " + JSON.stringify(error));
      });

      // Method called when tapping on a notification
      var self = this;
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (event) => {
          let serviceId = "";
          try {
            serviceId = event.notification.data.url.split("/chat/")[1];
            if (serviceId.length > 0) {
              self.$router.push({
                name: "Chat",
                params: {
                  id: serviceId,
                },
              });
            }
          } catch (err) {
            console.log("err " + err);
          }
        }
      );
    },
    startIntercomMessenger(user) {
      if (!this.intercom.ready) {
        this.intercom.once("ready", () => this.rebootIntercom(user));
      } else {
        this.rebootIntercom(user);
      }
    },
    rebootIntercom(user) {
      this.avaibleChatSupport = true;
      this.intercom.shutdown();

      if (this.intercom.isBooted) return;
      this.intercom.boot(user);
    },
    onlineOffline() {
      window.addEventListener("offline", () => {
        this.onlineEvent(false);
      });

      window.addEventListener("online", () => {
        this.onlineEvent(true);
      });
    },
  },
  mounted() {
    this.userData = {
      userId: this.user.id,
      name: `${this.user.firstName} ${this.user.lastName}`,
      email: this.user.email,
      supplier_name: this.user.supplierName,
      hide_default_launcher: true,
    };

    // Tomar el estado actual
    if (navigator.onLine) {
      this.onlineEvent(true);
    } else {
      this.onlineEvent(false);
    }

    this.onlineOffline();
    this.startIntercomMessenger(this.userData);
    this.initFirebaseChat();
    //this.initGpsTracking();
    if (CapacitorApp.getPlatform() != "web") {
      this.initPushMessaging();
    }else{
      this.initPushnotificationWeb();
    }
    this.checkRoute();
  },
  destroyed() {
    if (this.listener) {
      this.listener(); //off
    }
    if (this.autoAuth) {
      this.autoAuth(); //off
    }
    store.state.chat.activeChats = [...[]];
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
};
</script>

<style scoped>
.v-item-group.v-bottom-navigation .v-btn {
  max-width: 100%;
}
.full-h {
  height: 100%;
}
.text-white {
  color: #fff;
}
.pt10 {
  padding-top: 13px !important;
}
</style>

<style scoped>
	/* Animation property */
.__shake {
    animation: wiggle 2s linear infinite;
}
/* Keyframes */
@keyframes wiggle {
    0%,
    7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-15deg);
    }
    20% {
        transform: rotateZ(10deg);
    }
    25% {
        transform: rotateZ(-10deg);
    }
    30% {
        transform: rotateZ(6deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    40%,
    100% {
        transform: rotateZ(0);
    }
}
</style>
